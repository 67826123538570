$main = $('.js-main');
$menu = $('#menu');
$menuIcon = $('.mobile-menu-icon');
$section = $('.section-js');
$mobileMenu = $('.mobile-menu');
$header = $('#header');
$services = $('.services');


// sub menu
//
// function submenu() {
//
//   $services.on('mouseover', function(e) {
//     $('.sub-nav').toggleClass("services fade-in animation-duration-5");
//     // $($mobileMenu).toggleClass("open");
//     // $($main).toggleClass("menu-open");
//     // $(nav).removeClass("hide");
//
//
//
//     // $menuIcon.toggleClass("open");
//     // $main.toggleClass("exit-animation");
//
//   });
// }
//
// submenu()




// mobile menu

function menu() {

  $menuIcon.on('click', function (e) {
    $($header).toggleClass("open");
    // $($mobileMenu).toggleClass("open");
    // $($main).toggleClass("menu-open");
    // $(nav).removeClass("hide");



    // $menuIcon.toggleClass("open");
    // $main.toggleClass("exit-animation");

  });
}

menu();

// animate on scroll

function animations() {

  var $section = $('.section-js')

  $section.each(function (index, element) {
    var wrapper = $(this);
    var elementWatcher = scrollMonitor.create(wrapper, {
      top: -150
    });

    if ($(this).hasClass('full-reveal')) {

      elementWatcher.fullyEnterViewport(function () {
        wrapper.addClass('start-animation');
        wrapper.removeClass('opacity-0');
      });


    } else {

      elementWatcher.enterViewport(function () {
        wrapper.addClass('start-animation');
        wrapper.removeClass('opacity-0');


      });

    }
  });

}

animations();


// parallax


function parallax() {


  $(window).scroll(function () {
    var scrollTop = $(window).scrollTop(),
      logo = $('.logo-bg'),
      logo_offset = logo.offset()


    var $parallax_container = $('.parallax_container');

    $parallax_container.each(function () {

      var container_offset = $(this).offset(),
        object_offset = $(this).find('.parallax').offset(),
        h_start = scrollTop - container_offset.top,
        v_start = scrollTop - container_offset.top;


      var left = h_start / -10 + 'px';
      var right = h_start / 10 + 'px';
      var top = v_start / -8 + 'px';
      var bottom = v_start / 8 + 'px';


      $(this).find('.parallax-left').css('transform', 'translate3d(' + left + ', 0 ,0)');
      $(this).find('.parallax-right').css('transform', 'translate3d(' + right + ', 0 ,0)');
      $(this).find('.parallax-top').css('transform', 'translate3d(0, ' + top + ' ,0)');
      $(this).find('.parallax-bottom').css('transform', 'translate3d(0, ' + bottom + ' ,0)');

    })
  })


}

parallax();


// owl carousel

function callback(event) {
  $('.owl-carousel.gallery').toggleClass('touch-grab')
}



function carousel() {

  $(document).ready(function () {
    $('.owl-carousel.gallery').owlCarousel({
      autoWidth: true,
      // stagePadding: 50,
      responsiveClass: true,
      dots: true,
      nav: false,
      loop: false,
      margin: 30,
      mouseDrag: true,
      touchDrag: true,
      onDrag: callback,
      onDragged: callback,

      responsive: {
        0: {
          // items: 1,
        },
        600: {
          // items: 2,
        },
        1000: {
          // items: 3,
        }
      }
    })
  });


  $(document).ready(function () {
    $('.owl-carousel.portfolio').owlCarousel({
      autoWidth: true,
      // stagePadding: 50,
      responsiveClass: true,
      dots: true,
      nav: false,
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      onDrag: callback,
      onDragged: callback,

      responsive: {
        0: {
          margin: 10,
        },
        600: {
          margin: 30,
        },
        1000: {
          margin: 30,
        }
      }
    })
  });


  $(document).ready(function () {
    $('.owl-carousel.full-carousel').owlCarousel({
      autoWidth: false,
      responsiveClass: true,
      dots: true,
      dotsData: true,
      nav: true,
      animateOut: 'fade-in-from-top',
      animateOut: 'fade-out',

      loop: true,
      dotsSpeed: '250',
      autoplayHoverPause: true,
      autoplay: false,


      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 1,
          nav: true
        },
        1000: {
          items: 1,
          nav: true,
          dots: true,
        }
      }
    });
  });

}

carousel();



// close nav on scroll

function nav() {

  var prev = 0;
  var $window = $(window);

  $(document).ready(function () {


    $window.on('scroll', function () {

      if (!$($main).hasClass("menu-open")) {

        var scrollTop = $window.scrollTop();

        if (scrollTop > 300) {
          $header.toggleClass('hide', scrollTop > prev);
          prev = scrollTop;
        }

      }


    })

  })

}
nav();


// Anchor animations


function onScroll(event) {
  var scrollPos = $(document).scrollTop() + 50;

  $('.service .scrollLink').each(function () {
    var currLink = $(this);
    var ref = $(currLink.attr("href"));
    var service = $('.service-menu');


    // console.log(scrollPos, scrollPos)


    // console.log(ref);
    var refElement = ref.position().top;
    if (refElement <= scrollPos && refElement + ref.height() >= scrollPos) {
      console.log('yes');
      $('.service a.scrollLink').removeClass("active");
      currLink.addClass("active");
    } else {
      // console.log('no');

      currLink.removeClass("active");
    }
  });
}


$('.email').each(function (index, element) {
  $(this).on('click', function (e) {
    fathom('trackGoal', 'QDTPV4KO', 0);
  });

})

$('.phone').each(function (index, element) {
  $(this).on('click', function (e) {
    fathom('trackGoal', '6KXSOOUJ', 0);
  });

})

$('.social').each(function (index, element) {

  $(this).on('click', function (e) {
    fathom('trackGoal', 'WZMHYHII', 0);
  });

})

$(document).ready(function () {

  $('a[href^="tel:"]').addClass('phone');
  $('a[href^="mailto:"]').addClass('email');


})

$('.email').each(function (index, element) {
  $(this).on('click', function (e) {
    fathom('trackGoal', 'TOPITZM4', 0);
  });

})

$('.phone').each(function (index, element) {
  $(this).on('click', function (e) {
    fathom('trackGoal', 'GVFIHLIY', 0);
  });

})



$(document).ready(function () {

  $(window).bind("pageshow", function (event) {
    $('html').removeClass('animation-override ')
  });

  $(document).on("scroll", onScroll);

  $('.service .scrollLink').each(function () {


    $(this).on('click', function (e) {
      e.preventDefault();
      // $(document).off("scroll");

      $($main).removeClass("menu-open");
      $($mobileMenu).removeClass("open");
      $($menuIcon).removeClass("open");
      var target = this.hash,
        // target=  $('.service-menu');
        // menu = target; d
        $target = $(target);
      $('html, body').stop().animate({
        'scrollTop': $target.offset().top
      }, 500, 'swing', function () {
        window.location.hash = target;
        $(document).on("scroll", onScroll);
      });
      // $(this).removeClass('bg-textcolor text-cream');
    })
    // $(this).addClass('bg-textcolor text-cream');
    console.log();

  });
});



// loadout animations
$(document).ready(function () {


  $('a').each(function () {

    if ($(this).hasClass('scrollLink')) {

      // $(this).on('click', function(e) {
      //
      //   $(document).on("scroll", onScroll);
      //   e.preventDefault();
      //   $(document).off("scroll");
      //
      //   var target = this.hash,
      //     menu = target;
      //   $target = $(target);
      //   $('html, body').stop().animate({
      //     'scrollTop': $target.offset().top - 2
      //   }, 500, 'swing', function() {
      //     window.location.hash = target;
      //     $(document).on("scroll", onScroll);
      //   });
      //
      // })


    } else if ($(this).attr('target') == '_blank') {


    } else if ($(this).hasClass('phone')) {

    } else if ($(this).hasClass('email')) {

    } else if ($(this).hasClass('card')) {

      $(this).on('click', function (e) {

        var href = $(this).attr("href");

        $('html').addClass('animation-override ')

        $('body').addClass(' fade-out animation-duration-3 animation-easeOut-1')

        window.location = href;


      })

    } else {

      $(this).on('click', function (e) {

        var href = $(this).attr("href");


        if (href == "#0") {
          return false;
        }

        $('html').addClass('animation-override ')

        $('body').addClass(' fade-out animation-duration-3 animation-easeOut-1')

      })

    }
  })

})
